import * as React from "react";
import Meta from "../components/meta";
import { PostModel, CompanyModel, ImageModel } from "../models";
import { graphql } from "gatsby";
import { Image } from "../components/image";
import { RichText } from "../components/richtext";
import * as Css from "../styles/post.module.scss";

const PostPage: React.FC<PostPageModel> = ({ data: { contentfulCompany, contentfulPost } }) => {
    const pageMeta = {
        meta: {
            title: contentfulPost.postName,
            description: contentfulPost.author,
        },
        createdAt: new Date(contentfulPost.date).toDateString(),
        updatedAt: new Date(contentfulPost.date).toDateString(),
    };
    const formatted_date = new Date(contentfulPost.date).toLocaleDateString(undefined, {
        year: "numeric",
        month: "short",
        day: "numeric",
    });
    return (
        <>
            <Meta page={pageMeta} company={contentfulCompany} />
            <header>
                <Image image={contentfulCompany.logo} onClick={() => window.location.assign(`/`)} />
            </header>

            <main>
                <section className={Css.titleSection}>
                    <div className={Css.headingImage}>
                        <Image image={contentfulPost.headerImage} />
                    </div>
                    <h1>{contentfulPost.postName}</h1>
                </section>
                <section className={Css.main}>
                    <div className={Css.postInfo}>
                        <p>By {contentfulPost.author}</p>
                        <p>{formatted_date}</p>
                    </div>

                    <RichText
                        text={contentfulPost.mainText}
                        className={Css.text}
                        referencesClassName={Css.references}
                    />
                </section>
            </main>

            <footer>
                <h1>#Say hello</h1>
                <div>
                    <div>
                        <h2>Email</h2>
                        <p>{contentfulCompany.emailAddress}</p>
                    </div>
                    <div>
                        <h2>Address</h2>
                        <p>{contentfulCompany.physicalAddress}</p>
                    </div>
                    <div>
                        <h2>Phone</h2>
                        <p>{contentfulCompany.mobilePhone}</p>
                    </div>
                    <div>
                        <p>{contentfulCompany.copyright}</p>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default PostPage;

export interface PostPageModel {
    data: {
        contentfulPost: PostModel;
        contentfulCompany: CompanyModel;
    };
}

export const postQuery = graphql`
    query PostPageQuery($slug: String) {
        contentfulPost(slug: { eq: $slug }) {
            ...postFields
        }
        contentfulCompany {
            ...companyFields
        }
    }
`;
